import React, { useEffect, useRef } from "react";
import { Box } from "@mui/material";

const GoogleTranslate = () => {
  const googleTranslateScriptRef = useRef(null);
  const intervalRef = useRef(null);

  useEffect(() => {
    const handleScriptLoad = () => {
      const initGoogleTranslate = () => {
        if (
          window.google &&
          window.google.translate &&
          window.google.translate.TranslateElement
        ) {
          new window.google.translate.TranslateElement(
            { pageLanguage: "en" },
            "google_translate_element_navbar"
          );
          clearInterval(intervalRef.current);
        }
      };

      initGoogleTranslate();
      intervalRef.current = setInterval(initGoogleTranslate, 100);
    };

    const script = document.createElement("script");
    script.src =
      "https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit";
    script.async = true;
    script.onload = handleScriptLoad;
    // Changed the onerror event handler to log the error message
    script.onerror = (error) => console.error("Error loading Google Translate script:", error.message);
    document.body.appendChild(script);

    googleTranslateScriptRef.current = script;

    return () => {
      document.body.removeChild(googleTranslateScriptRef.current);
      clearInterval(intervalRef.current);
    };
  }, []);

  return (
    <div>
      <Box
        id="google_translate_element_navbar"
        sx={{ flexGrow: 0, margin: "auto" }}
      />
    </div>
  );
};

export default GoogleTranslate;

import React, { useEffect, useState } from "react";
import { Typography } from "@mui/material";
import { Container } from "@mui/system";
import Layout from "../components/layouts/Layout";
import Breadcrumbs from "../components/breadcrumbs";
import PagesPlaceHolder from "../components/placeholders/PagesPlaceHolder";
import { useSelector } from "react-redux";
import { selectData } from "../store/reducers/settings";
import { useTranslation } from "react-i18next";

const CookiePolicy = () => {
  const { t } = useTranslation();
  const data = useSelector(selectData);
  const [isloading, setLoading] = useState(true);

  useEffect(() => {
    if (data) {
      setLoading(false);
    }
  }, []);

  console.log("first", data);

  let string = data.cookies[0].toString();

  let cleanedPrivacyPolicy = "";
  if (typeof string === "string") {
    cleanedPrivacyPolicy = string
      .replaceAll("&lt;!-- /wp:list-item --&gt;", "")
      .replaceAll("&lt;!-- wp:list-item --&gt;", "")
      .replaceAll("/wp:paragraph", "")
      .replaceAll("wp:paragraph", "")
      .replaceAll("/wp:list", "")
      .replaceAll("wp:list", "")
      .replaceAll("/wp:heading", "")
      .replaceAll("wp:heading", "")
      .replaceAll("/wp:table", "")
      .replaceAll("wp:table", "")
      .replaceAll("--&gt;", "")
      .replaceAll(`{"level":3,"fontSize":"medium"}`, "")
      .replaceAll("&lt;!--", "")
      .replaceAll(`{"level":3}`, "");
  }

  return (
    <Layout title={t("cookie_policy")}>
      <Breadcrumbs title={t("cookie_policy")} crumb={t("cookie_policy")} />
      <Container>
        <div className="title-wrapper">
          <Typography variant="h4" component="h4" className="bold">
            Cookie {""}
            <span className="highlight">Policy</span>
          </Typography>
          {/* <Typography weight="light">{t("cookie_policy_title")}</Typography> */}
        </div>
        <div className="privacy-content">
          {isloading ? (
            <PagesPlaceHolder />
          ) : (
            <>
              <Typography
                mt={-4}
                variant="body1"
                component="h5"
                sx={{
                  wordBreak: "break-word",
                  whiteSpace: "normal",
                }}
                dangerouslySetInnerHTML={{ __html: cleanedPrivacyPolicy }}
              />
            </>
          )}
        </div>
      </Container>
    </Layout>
  );
};

export default CookiePolicy;

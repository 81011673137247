let config = {
  // default country set
  DefaultCountrySelectedInMobile: "es",
  // default language
  DefaultLanguage: "en",

  //google Places key
  YOUR_GOOGLE_MAPS_API_KEY: process.env.REACT_APP_MAP_API_KEY,
};

export default config;

import React, { useState, useEffect } from "react";
import { NavLink, useLocation, useMatch } from "react-router-dom";
import { Grid } from "@mui/material";
import Login from "../common/Login";
import { Box, Tooltip } from "@mui/material";
import GoogleTranslate from "./GoogleTranslate";
import LanguageIcon from "@mui/icons-material/Language";
import IconButton from "@mui/material/IconButton";

const MobileNav = ({ logo }) => {
  const isHomePage = useMatch("/");
  const path = useLocation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const handleToggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  useEffect(() => {
    // Add event listener to toggle menu when clicking anywhere on the screen
    document.body.addEventListener("click", handleClickOutside);

    // Cleanup function to remove event listener when component unmounts
    return () => {
      document.body.removeEventListener("click", handleClickOutside);
    };
  }, []);
  const handleClickOutside = (event) => {
    // Close the menu if clicked outside of the menu button and the menu itself
    if (
      !event.target.closest(".share-btn") &&
      !event.target.closest(".navbar-menu")
    ) {
      setIsMenuOpen(false);
    }
  };

  return (
    <nav
      className="mobile-header home-header"
      style={
        isHomePage
          ? { background: "transparent" }
          : { background: "var(--primary-color--)" }
      }
    >
      <Grid
        container
        className="mobileNav"
        style={{ alignItems: "center", paddingTop: "0px" }}
      >
        <Grid item md={6} xs={4} className="mobileNavGrid">
          <div className="logo">
            <NavLink to="/">
              <div className="header-logo">
                <img src={logo} alt="logo" style={{ maxHeight: "80px" }} />
              </div>
            </NavLink>
          </div>
        </Grid>
        <Grid
          item
          md={6}
          xs={6}
          className="mobileNavGrid"
          gap={"-8px"}
          sx={{ marginLeft: "auto" }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
            }}
          >
            <Box>
              <Login />
            </Box>

            <Tooltip title="Language">
              <IconButton
                className="share-btn"
                onClick={handleToggleMenu}
                sx={{ fontSize: "18px", color: "#fff" }}
              >
                <LanguageIcon />
              </IconButton>
            </Tooltip>
            {isMenuOpen && (
              <div className="navbar-menu">
                <GoogleTranslate />
              </div>
            )}
          </Box>
        </Grid>
      </Grid>
      <div id="recaptcha-container"></div>
    </nav>
  );
};

export default MobileNav;
